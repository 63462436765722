import { Component, HostListener, OnInit } from "@angular/core";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { HttpsService } from "./services/https/https.service";
import firebase from "firebase/app";
import "firebase/messaging";
import { MessagingService } from "./services/messaging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "SuperPanel";
  readonly VAPID_PUBLIC_KEY: string = environment.VAPID_PUBLIC_KEY;
  serviceWorkerAttempt: number = 0;

  deferredPrompt: any;
  showButton = false;
  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  constructor(private https: HttpsService, private swPush: SwPush, private swUpdate: SwUpdate, private messagingService: MessagingService) {
    navigator.serviceWorker.register("ngsw-worker.js");
    firebase.initializeApp(environment.firebase);
    const setInt = () => {
      navigator.serviceWorker.getRegistration().then((swr: any) => {
        this.serviceWorkerAttempt++;
        console.log("swr", swr);
        if (swr != undefined && swr.active !== null) {
          firebase.messaging().useServiceWorker(swr);
        } else {
          if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 3) {
            setInt();
          }
        }
      });
    };
    setInt();
    swUpdate.available.subscribe((_) =>
      swUpdate.activateUpdate().then(() => {
        console.log("reload for update");
        document.location.reload();
      })
    );
    swPush.messages.subscribe((msg) => console.log("push message", msg));
    swPush.notificationClicks.subscribe((click) => console.log("notification click", click));
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      this.deferredPrompt = null;
    });
  }

  ngOnInit() {
    setTimeout(() => {
      console.log("run");
      this.permitToNotify();
      // this.messagingService.receiveMessage();
    }, 3000);
  }

  permitToNotify() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() =>
        messaging.getToken().then((token: any) => {
          console.log(token);

          this.messagingService.token = token;
        })
      )
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }
 
}
